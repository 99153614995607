import React from 'react';
import Layout from "../components/Layout";
import {Container, Typography} from "@material-ui/core";
import {graphql, Link, useStaticQuery} from "gatsby";

export default function Error() {

    const data = useStaticQuery(graphql`
    {
      errorHeroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "wood-716610_1920.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <Layout heroHeadline1="Seite nicht gefunden"
                heroImage={data.errorHeroImage.img.fluid}
        >
            <Container maxWidth="sm" style={{paddingBottom: '50px'}}>
                <Typography component="div" style={{height: '5vh'}}>
                    Diese Seite gibt es leider nicht.
                </Typography>
                <Link
                    color="inherit"
                    variant="button"
                    href="/"
                    to="/"
                >
                    Zurück zur Startseite
                </Link>
            </Container>
        </Layout>
    );
}
